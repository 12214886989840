//@ts-nocheck
import React, { lazy, Suspense } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  withRouter
} from 'react-router-dom';

//* Routes List start *//
const SplashscreenWeb = lazy(() =>
  import('../../blocks/splashscreen/src/SplashScreen.web')
);
const UTSSideBar = lazy(() =>
  import('../../blocks/adminconsole2/src/UTSSideBar.web')
);
const Poll = lazy(() =>
  import('../../blocks/poll/src/Poll.web')
);
const CurrentMatch = lazy(() =>
  import('../../blocks/currentmatch/src/CurrentMatch.web')
);
const CreatePollScreen = lazy(() =>
  import('../../blocks/polling/src/CreatePollScreen.web')
);
const UTSLoginScreen = lazy(() =>
  import('../../blocks/email-account-login/src/UTSLoginScreen.web')
);
const EmailSignup = lazy(() =>
  import('../../blocks/mobile-account-registration/src/EmailSignup')
);
const NewsFeed = lazy(() => import('../../blocks/newsfeed/src/NewsFeed.web'));

const PhoneNumberRegistration = lazy(() =>
  import('../../blocks/mobile-account-registration/src/PhoneNumberRegistration.web')
);
const PollingResult = lazy(() =>
  import('../../blocks/polling/src/PollingResult.web')
);

//* Routes List End *//

//* Private Routes List start *//
import PrivateRoute from './PrivateRoute.web';
import Loader from '../../components/src/Loader.web';
//* Private Routes List end *//

function WebRoutes(rootProps: any) {
  return (
    <Router>
      <Suspense fallback={<Loader loading />}>
        <Switch>
          <Route
            path="/"
            // exact
            render={props => <UTSLoginScreen {...props} />}
          />
          {/* <Route
            path="/signup"
            exact
            render={props => <EmailSignup {...props} />}
          />
          <Route
            path="/landing"
            exact
            render={props => <PhoneNumberRegistration {...props} />}
          />
          <Route
            path="/"
            exact
            render={props => <SplashscreenWeb {...props} />}
          />
          <Route
            path="/poll-result/:id"
            exact
            render={props => <PollingResult {...props} />}
          />

          <PrivateRoute
            path="/newsfeed"
            exact
            render={props => <NewsFeed {...props} />}
          />
          <Route
            path="/poll/:id"
            exact
            render={props => <Poll {...props} />}
          /> */}
        </Switch>
      </Suspense>
    </Router>
  );
}

export default withRouter(WebRoutes);
